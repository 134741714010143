import React, { useState, useEffect } from 'react';
import '../App.css';
import axios from 'axios'
import $, { event } from 'jquery';


function Login() {
  const ClickLogin = (idValue, passwordValue) => {
    console.log(idValue)
    console.log(passwordValue)
    axios.post('/api/v1/login', {
      userId: idValue,
      userPassWord: passwordValue
    },
      {
        withCredentials: true
      }
    )
      .then((response) => {
        console.log('response', response);
        const loginChk = response.data["loginChk"]
        console.log('loginChk', loginChk);

        if (loginChk === true) {
          // /main으로 페이지 이동
          // history.push('/main');
          // Login 함수 내에서 값 저장
          sessionStorage.setItem('idValue', idValue);

          // window.location.href = "/main"; // 원본
          window.location.href = "/home"; // 수정 TODO

        } else {
          // 로그인 실패
          alert("아이디 또는 비밀번호를 확인해주세요.");
          void 0;
        }

      })
      .catch((error) => {
        console.log(error)
        console.log('false');
      });
  }
  // window.location.href = "/main";
  // cors 정책으로 백엔드 요청 못함(추후 수정)
  const [idValue, setIdValue] = useState("admin01")
  const [passwordValue, setPasswordValue] = useState("view01!")
  // const idValue = document.getElementById('inputID').value;
  // const passwordValue = document.getElementById('inputPassword').value;

  return (
    <>
      <div className="intro-frame">
        <h2>뷰성형외과 관리자 페이지</h2>
        <div className="intro-imgcontainer">
          <img src="img/viewbeauty_01.svg" alt="Avatar" className="avatar" />
        </div>

        <div className="container">
          <label className="input-data"><b>관리자 ID</b></label>
          <input className="search-input" type="text" name="id" id='inputID' value={idValue} onChange={(event) => { setIdValue(event.target.value) }} />

          <label className="input-data"><b>Password</b></label>
          <input className="search-input" type="password" name="call_id" id='inputPassword' value={passwordValue} onChange={(event) => { setPasswordValue(event.target.value) }} />
          <button type="submit" className="search-button" onClick={() => ClickLogin(idValue, passwordValue)}>로그인</button>
        </div>
      </div>
    </>
  )
}

export default Login
