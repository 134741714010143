import React, { useState, useEffect } from 'react';
import axios from 'axios';


const FileInfo = ({ filesInfo, onRemoveFile }) => (
    <ul className='preview_info' style={{ paddingLeft: 0 }}>
        {filesInfo.map((file, index) => (
            <li key={index} style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: '5px',
                background: 'rgba(0, 0, 0, 0.2)',
                padding: '3px',
                borderRadius: '5px'
            }}>
                <span className='info_key' style={{
                    flex: "0 0 30px",
                    textAlign: 'right',
                    paddingRight: '10px'
                }}>
                    ({index + 1})
                </span>
                <span className='info_value'
                    style={{
                        flex: "1 1 auto",
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '235px',
                        whiteSpace: 'nowrap'
                    }}>
                    {file.name}
                </span>
                <span style={{
                    flex: "0 0 80px",
                    textAlign: 'right',
                    paddingLeft: '10px'
                }}>
                    ({file.size})
                </span>
                <button className='remove-item-btn' style={{
                    flex: "0 0 50px",
                    height: '25px',
                    marginLeft: '10px',
                    backgroundColor: 'red',
                    color: 'white',
                    borderRadius: '5px'
                }}
                    onClick={(event) => { event.stopPropagation(); onRemoveFile(index); }}>
                    삭제
                </button>
            </li>
        ))}
    </ul>
)

function FileUploadComponent({ onUploadSuccess }) {
    const [selectedFiles, setSelectedFiles] = useState([]); // 유저가 업로드 창에서 선택해서 업로드한 파일
    const [uploadedInfo, setUploadedInfo] = useState([]); // 선택 후 업로드된 파일의 '파일 정보'
    const [isUploadLoading, setIsUploadLoading] = useState(false);
    const [isFileValid, setIsFileValid] = useState(true);
    const [isDragOver, setIsDragOver] = useState(false);  // 드래그 상태 관리

    useEffect(() => {
        console.log('selected file check :', selectedFiles);
        console.log('upload file check :', uploadedInfo);
    }, [selectedFiles, uploadedInfo]);

    useEffect(() => {
        const newFilesArray = selectedFiles.map(file => {
            const { name, size: bytesize, type } = file;
            const size = (bytesize / (1024 * 1024)).toFixed(2) + 'MB';
            return { name, size, type };
        });
        console.log('upload files info :', newFilesArray);
        setUploadedInfo(newFilesArray);
    }, [selectedFiles]);

    const handleFileChange = async (event) => {
        handleFiles(event.target.files);
    }

    const handleFiles = async (files) => {
        // const newFiles = event.target.files;

        // 사용자가 파일 선택을 취소했을 때, 기존 파일 정보를 유지
        if (files.length === 0) {
            return;
        }

        if (Array.from(files).some(file => file.type !== 'application/pdf')) {
            alert('모든 파일은 PDF 형식이어야 합니다.');
            return;
        }

        // 로컬에서 이미 선택된 파일 목록에 있는지 확인
        const existingFileNames = selectedFiles.map(file => file.name);
        console.log('existing file names:', existingFileNames);
        const newValidFiles = [];
        const localDuplicateFiles = [];

        Array.from(files).forEach(file => {
            if (existingFileNames.includes(file.name)) {
                localDuplicateFiles.push(file.name);
                console.log('local duplicate files:', localDuplicateFiles);
            } else {
                newValidFiles.push(file);
            }
        });

        // 로컬에서 중복된 파일이 있는 경우 경고
        if (localDuplicateFiles.length > 0) {
            console.log('local duplication alert')
            alert(`다음 파일은 이미 선택하셨습니다: ${localDuplicateFiles.join(', ')}. 다른 파일을 선택해 주세요.`);
            return; // 중복 파일이 있으면 추가 안 함
        }

        // 서버에서 중복 확인
        const fileNames = newValidFiles.map(file => file.name);
        const duplicates = await checkFilesDuplicate(fileNames);
        if (duplicates.length > 0) {
            console.log('server duplication alert')
            alert(`다음 파일은 이미 업로드되었습니다: ${duplicates.join(', ')}. 다른 파일을 선택해 주세요.`);
            setIsFileValid(false);
            return;
        } else {
            // 중복이 없으면 파일 목록에 추가
            setSelectedFiles(prev => [...prev, ...newValidFiles]);
            setIsFileValid(true);
        }
    };

    // 업로드 파일 중복 검사
    const checkFilesDuplicate = async (fileNames) => {
        try {
            const response = await axios.get(`/api/v1/pdf/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`
                }
            });
            console.log('get faq list for checking duplication >>', response.data.files)
            const hasCommonElement = fileNames.some(fileName => response.data.files.includes(fileName));
            console.log('duplication data >>', hasCommonElement);
            return hasCommonElement;
        } catch (error) {
            console.error('Error checking file duplicates >>', error);
            return [];
        }
    };

    // 드래그 앤 드롭 이벤트 핸들러
    const handleDragOver = (e) => {
        e.preventDefault();  // 기본 이벤트 방지
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragOver(true);  // 드래그 상태 true로 설정
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);  // 드래그 상태 false로 설정
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);  // 드랍 이후 드래그 상태 false로 설정
        const files = e.dataTransfer.files; // 드롭된 파일들을 가져옵니다
        handleFiles(files);  // 파일 핸들링 로직을 재사용
    };

    const handleDeleteFile = (index) => {
        const newFilesArray = [...selectedFiles];
        newFilesArray.splice(index, 1);
        setSelectedFiles(newFilesArray);
    };

    const handleUpload = async (e) => {
        e.preventDefault();

        if (selectedFiles.length === 0) {
            alert('파일을 먼저 선택해주세요.');
            return;
        };

        if (selectedFiles.length > 10) {
            alert('파일은 최대 10개까지 업로드가 가능합니다.');
            return;
        };

        const confirmUpload = window.confirm("선택한 파일로 업데이트 하시겠습니까?");
        if (!confirmUpload) {
            return;  // 유저가 업로드를 취소할 경우 그대로 두기
        }

        setIsUploadLoading(true);

        if (selectedFiles.length > 0 && selectedFiles.length < 11 && isFileValid) {
            const formData = new FormData();
            Array.from(selectedFiles).forEach(file => {
                formData.append('files', file);
            });

            try {
                const response = await axios.post(`/api/v1/pdf/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    },
                    timeout: 20000,  // 타임아웃 20초 설정
                });
                console.log('upload response', response);

                if (response.status === 200) {
                    let alreadyExistsFiles = [];
                    let otherErrorFiles = [];
                    let successFiles = [];

                    console.log('upload response.data :', response.data);

                    response.data.forEach(file => {
                        if (file.status === 200) {
                            successFiles.push(file.file_name);
                        } else if (file.message === 'The file already exists' || file.status === 409) {
                            alreadyExistsFiles.push(file.file_name);
                        } else {
                            otherErrorFiles.push(file.file_name);
                        }
                    });

                    if (successFiles.length > 0) {
                        alert('성공적으로 업로드된 파일들:\n' + successFiles.join('\n'));
                        onUploadSuccess(true, Array.from(selectedFiles).map(file => file.name)); // 부모 컴포넌트에 업로드 성공 알림
                    }
                    if (alreadyExistsFiles.length > 0) {
                        alert('이미 파일을 업로드 했습니다. :' + alreadyExistsFiles.join('\n'));
                    }
                    if (otherErrorFiles.length > 0) {
                        alert('파일 업로드 실패 :' + otherErrorFiles.join('\n'));
                    }
                    setUploadedInfo([]);
                    setSelectedFiles([]);
                } else {
                    console.error('Unexpected response status:', response.status)
                    alert('서버 오류! 관리자에게 문의하세요.')
                }
            } catch (error) {
                if (error.code === 'ECONNABORTED') {
                    console.error('Timeout error:', error.message);
                    alert('처리하는데 시간이 너무 오래 걸립니다.');
                } else {
                    console.error('upload fail:', error);
                    alert('에러가 발생했습니다.');
                }
            } finally {
                setIsUploadLoading(false);
            }
        }
    };

    return (
        <>
            <div className='pdf-upload-label'
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                style={{
                    border: isDragOver ? '2px solid blue' : '',
                    color: isDragOver ? 'white' : '',
                    backgroundColor: isDragOver ? '#2e200e8f' : ''
                }}>
                <input
                    id='file-input'
                    type="file"
                    name='file'
                    accept='.pdf'
                    multiple
                    className='file-input'
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
                {isFileValid ? null : <p>하나 이상의 파일 이름이 중복됩니다.</p>}
                {uploadedInfo.length > 0 && (
                    <>
                        <FileInfo filesInfo={uploadedInfo} onRemoveFile={handleDeleteFile} />
                        <div className='upload-buttons'>
                            <button
                                className='btn-add-more'
                                style={{
                                    background: 'skyblue',
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '100px',
                                    marginBottom: '10px',
                                    fontSize: '25px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => document.querySelector('.file-input').click()}>
                                +
                            </button>
                            <button className='btn-server' onClick={handleUpload} disabled={!isFileValid}>
                                데이터 업로드
                            </button>
                        </div>
                    </>
                )}
                {uploadedInfo.length === 0 && (
                    <>
                        <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
                            <img src='/img/file_upload_icon.png' alt='file-upload' />
                        </label>
                        <p>아이콘을 클릭하여 PDF 다중 업로드 하기</p>
                    </>
                )}
            </div>
        </>
    );
}

export default FileUploadComponent;